/* cart header*/
* {
  word-break: keep-all;
}
.header {
  display: flex;
  justify-content: center;
  max-width: 1280px;
  margin: 0 auto;
  margin-top: 54px;
  z-index: 1;
}
.moreInfoKr {
  font-family: "sandoll-gothicneo2", sans-serif;
  font-weight: 400;
  font-style: normal;
  box-sizing: border-box;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  letter-spacing: 0.02em;
}
.moreInfoEng {
  font-family: ABCWhyte;
  letter-spacing: -0.02em;
  position: relative;
  top: -0.05em;
}
.moreInfowrap {
  background-color: #292929;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 40px;
  position: relative;
  left: 0px;
  padding: 0px 50px 0px 50px;
  grid-column: span 2;
  transition: all ease 0.8s;
  z-index: 1;
  box-sizing: border-box;
  max-height: 0px;
  overflow: hidden;
}
.moreInfowrap p {
  margin: 50px 0px 30px 0px;
}
.opened {
  display: grid;

  max-height: 100vh;
}

.tab_title {
  max-width: 1280px;
  height: 60px;
  border-radius: 10px;
  background: #f2f2f2;
  margin: 0 auto;
  margin-top: 52px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  place-items: center;
}

.tab_title span {
  background: #f2f2f2;
}

.tab_title input[type="checkbox"] {
  width: 20px;
  height: 20px;
  border: 2px solid #19ce60;
  border-radius: 50%;
  appearance: none;
  cursor: pointer;
}

.tab_title input[type="checkbox"]:checked {
  background: #19ce60;
  border: none;
}

.tab_title p {
  display: none;
  background: #f2f2f2;
  font-size: 18px;
  color: #767676;
}

/* cartList*/
.cart_product_list {
  position: relative;
  max-width: 1280px;
  height: 200px;
  border-radius: 10px;
  border: 1px solid #c4c4c4;
  margin: 0 auto;
  margin-top: 35px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  place-items: center;
}

.cart_product_list input[type="checkbox"] {
  width: 20px;
  height: 20px;
  border: 2px solid #19ce60;
  border-radius: 50%;
  appearance: none;
  cursor: pointer;
}

.cart_product_list input[type="checkbox"]:checked {
  background: #19ce60;
  border: none;
}

.cart_product_wrap {
  display: flex;
  align-items: center;
}

.cart_product_image {
  width: 160px;
  height: 160px;
  border-radius: 10px;
}

.cart_product_image img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.cart_product_info {
  width: 150px;
  margin-left: 36px;
}

.cart_product_info .seller_store {
  font-size: 12px;
  line-height: 18px;
  color: #767676;
  margin-bottom: 10px;
}

.cart_product_info .product_name {
  font-size: 15px;
  line-height: 22px;
  color: #000000;
  margin-bottom: 10px;
}

.cart_product_info .price {
  font-size: 15px;
  line-height: 20px;
  color: #000000;
  margin-bottom: 25px;
}

.cart_product_info .delivery {
  font-size: 14px;
  line-height: 18px;
  color: #767676;
}

.cart_product_count {
  position: relative;
  width: 110px;
  height: 45px;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
}

.cart_product_count .minus {
  position: absolute;
  width: 10px;
  height: 18px;
  top: 50%;
  left: 14px;
  transform: translateY(-50%);
  cursor: pointer;
}

.cart_product_count .count {
  position: absolute;
  width: 38px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #c4c4c4;
  border-top: none;
  border-bottom: none;
}

.cart_product_count .count span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cart_product_count .plus {
  position: absolute;
  width: 10px;
  height: 18px;
  top: 50%;
  right: 14px;
  transform: translateY(-50%);
  cursor: pointer;
}

.cart_product_price {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.cart_product_price .total_price {
  font-size: 18px;
  line-height: 23px;
  color: #eb5757;
}

.cart_product_price .btn_submit {
  width: 130px;
  height: 40px;
  background: #19ce60;
  border-radius: 5px;
  border: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
}

/* cart */
.not {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.not h2 {
  font-size: 18px;
  line-height: 23px;
  color: #000000;
}

.not p {
  font-size: 14px;
  line-height: 18px;
  color: #767676;
}

/* total cart */
.total {
  max-width: 1280px;
  height: 150px;
  background: #f2f2f2;
  border-radius: 10px;
  margin: 0 auto;
  margin-top: 35px;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  place-items: center;
}

.total p,
.total div,
.total img {
  background: #f2f2f2;
}

.total .total_price,
.total .sale,
.total .delivery,
.total .payment {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.total_price .cart_product_total_price,
.sale .cart_product_sale,
.delivery .cart_product_delivery {
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  margin-bottom: 12px;
}

.total_price .cart_product_price,
.sale .cart_product_sale_price,
.delivery .cart_product_delivery_price {
  font-size: 24px;
  line-height: 30px;
  color: #000000;
}

.pay_minus {
  position: relative;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  background: #ffffff !important;
}

.pay_minus img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: #ffffff;
}

.pay_plus {
  position: relative;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  background: #ffffff !important;
}

.pay_plus img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: #ffffff;
}

.payment .cart_prouct_payment {
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
  color: #000000;
  margin-bottom: 12px;
}

.payment .cart_prouct_payment_price {
  font-size: 36px;
  font-weight: bold;
  line-height: 30px;
  color: #eb5757;
}

.btn {
  width: 1280px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-top: 40px;
}

.btn .btn_submit {
  margin: 0 auto;
  width: 220px;
  height: 68px;
  background: #19ce60;
  border-radius: 5px;
  border: none;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  color: #ffffff;
}

.product_remove {
  position: absolute;
  top: 15px;
  right: 20px;
  width: 20px;
  height: 15px;
  cursor: pointer;
}

@media (max-width: 1280px) {
  .tab_title,
  .cart_product_list,
  .total {
    width: 95%;
  }
}

@media (max-width: 768px) {
  .moreInfowrap {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(4, auto);
    gap: 0px;
    overflow: scroll;
  }
  .moreInfowrap p:first-child {
    margin: 50px 0px 20px 0px;
  }

  .moreInfowrap p {
    margin: 0px 0px 20px 0px;
  }
  .tab_title {
    display: flex;
    padding-left: 50px;
  }
  .tab_title span {
    display: none;
  }

  .tab_title p {
    display: block;
    margin-left: 50px;
  }

  .cart_product_list {
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .cart_product_wrap {
    padding: 15px;
  }

  .cart_product_list input[type="checkbox"] {
    position: absolute;
    top: 15px;
    left: 20px;
  }

  .cart_product_list .cart_product_price {
    width: 80%;
  }

  .cart_product_count {
    left: 75px;
    margin-bottom: 10px;
  }

  .cart_product_price .btn_submit {
    width: 100%;
  }

  .pay_minus,
  .pay_plus {
    display: none;
  }
  .total {
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(2, 1fr);
    height: 175px;
    margin-bottom: 20px;
  }
}

@media (max-width: 480px) {
  .cart_product_image {
    width: 100px;
    height: 100px;
  }

  .cart_product_info p {
    font-size: 12px !important;
  }

  .cart_product_count {
    left: 45px;
  }

  .total_price .cart_product_total_price,
  .sale .cart_product_sale,
  .delivery .cart_product_delivery,
  .payment .cart_prouct_payment {
    font-size: 14px;
  }

  .total_price .cart_product_price,
  .sale .cart_product_sale_price,
  .delivery .cart_product_delivery_price {
    font-size: 18px;
  }

  .payment .cart_prouct_payment_price {
    font-size: 24px;
  }
}

@media (max-width: 360px) {
  .cart_product_info {
    width: initial;
  }
}
