#root {
}
@font-face {
  font-family: "ABCWhyte";
  src: local("ABCWhyte-Book"),
    url(../public/fonts/ABCWhyte-Book.woff) format("woff");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}
* {
  --backgroundColor: #282828;
  color: white;
  margin: 0;
  padding: 0;
  background: transparent;
}
body {
  background-color: var(--backgroundColor);
  color: inherit;
}
a {
  text-decoration: none;
}

ul,
li {
  list-style: none;
}

button {
  cursor: pointer;
}
