.product {
  display: inline;
  /* width: 100%; */
  /* overflow: hidden; */
  aspect-ratio: 1500/1500;
}
a {
  cursor: pointer;
}
.width100 {
  width: 100%;
  cursor: pointer;
  pointer-events: all;
  transition: all 0.2s ease;
}
.width100:hover {
  filter: opacity(0.3);
}
.product_image {
  width: 100%;
  height: auto;
  cursor: pointer;
}
.prduct_title {
  width: 100%;
  margin-top: 5px;
  display: block;
  text-align: center;
}
.product_image img {
  max-width: 100%;
  width: 100%;
  height: auto;
  max-height: 100%;
  top: 10%;
}
.BigColumn {
  grid-column: span 2;
}
.BigBigColumn {
  grid-column: span 3;
}
.SmallColumn {
  width: 70%;
  left: 15%;
}
.SmallSmallColumn {
  width: 60%;
  left: 20%;
}
.Development {
  grid-column: span 2;
}
.store {
  margin-bottom: 10px;
}

.store span {
  font-size: 16px;
  line-height: 22px;
  color: #767676;
}

.product_name {
  margin-bottom: 10px;
}

.product_name span {
  font-size: 18px;
  line-height: 22px;
  color: #000000;
}

.price {
  font-size: 24px;
  font-weight: bold;
  line-height: 30px;
  color: #000000;
}

.unit {
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}

@media (max-width: 968px) {
  .product_name span {
    font-size: 14px;
  }
  .width100:hover {
    filter: opacity(1);
  }
}

@media (max-width: 768px) {
}

@media (max-width: 360px) {
  .store span {
    font-size: 12px;
    line-height: 22px;
    color: #767676;
  }

  .product_name span {
    font-size: 12px;
  }
}
