.main {
}
.Applogo {
  position: relative;
  width: 30px;
  left: 10px;
  top: 10px;
  background-color: transparent;
  animation: ani 3s infinite;
  z-index: 2;
}
.logoArrow {
  position: absolute;
  left: 20px;
  top: 16.5px;
  font-size: 12px;
  line-height: 18px;

  background-color: transparent;
}
@keyframes ani {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
.logo {
  position: fixed;
  top: 50px;
  z-index: 2;
  background-color: var(--backgroundColor);
  width: 50px;
  height: 50px;
}
.post {
  /* margin-right: 50px; */
  padding: 12px 10px;
}

.post_img {
  width: 100%;
  height: 100%;
}
.post_imgthumb {
  width: calc(100vw - 20px);
  max-width: 1200px;
  position: relative;
  left: 50%;
  margin: 40px 0px;
  /* margin: 60px -50% 60px 0px; */
  transform: translateX(-50%);
}
.post_imgthumb img {
  width: 100%;
  height: 100%;
}

.post_info {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  justify-content: center;
  overflow: hidden;
  text-overflow: ellipsis;
  gap: 40px;
  left: 40px;
  width: calc(100% - 80px);
}

.post_info .post_name {
  box-sizing: border-box;
  font-size: 15px;
  grid-column: span 2;
  line-height: 24px;
  margin-bottom: 20px;
}
.post_desc {
  word-wrap: break-word;
  overflow-wrap: break-word;
  max-width: calc(100vw - 65px);
}
.post_descEng {
  word-wrap: break-word;
  overflow-wrap: break-word;
  max-width: calc(100vw - 65px);
}
.post_desc p {
  width: calc(100%);
  font-size: 15px;
  line-height: 24px;
  word-wrap: break-word;
  overflow-wrap: break-word;
}
.post_descEng p {
  width: calc(100%);
  display: block;
  height: fit-content;
  font-size: 15px;
  line-height: 22px;
  word-wrap: break-word;
  overflow-wrap: break-word;
}
.post_info .price {
  font-size: 36px;
  line-height: 45px;
  color: #000000;
  margin-bottom: 138px;
}
.OneImg {
  width: calc(100vw - 20px);
  max-width: 1200px;
  max-height: 100vh;
  position: relative;

  left: 50%;
  /* margin: 60px -50% 60px 0px; */
  transform: translateX(-50%);
  margin: 40px 0px;
}
.OneImg img {
  width: 100%;
  height: 100%;
  max-height: 100vh;
  object-fit: contain;
  overflow: hidden;
}
.Embed {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}
.Container {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
}
iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.TwoImg {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.OneColumnText {
  width: calc(100vw - 20px);
  max-width: 800px;
  position: relative;
  left: 50%;
  /* margin: 60px -50% 60px 0px; */
  transform: translateX(-50%);
  display: grid;
  gap: 15px;
  text-align: center;
}
.TwoColumnText {
  width: calc(100vw - 20px);
  max-width: 1200px;
  position: relative;
  left: 50%;
  /* margin: 60px -50% 60px 0px; */
  transform: translateX(-50%);
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
}
.ThreeImg {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
}
.ThreeColumnText {
  width: calc(100vw - 20px);
  max-width: 1200px;
  position: relative;
  left: 50%;
  /* margin: 60px -50% 60px 0px; */
  transform: translateX(-50%);
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
}
img {
  width: 100%;
}
.post_info .unit {
  font-size: 18px;
  line-height: 23px;
  color: #000000;
}

.sum {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.sum .sum_price {
  font-size: 18px;
  font-weight: 500;
  line-height: 23px;
  color: #000000;
}

.sum .total {
  font-size: 18px;
  line-height: 23px;
  color: #767676;
}

.sum .total_count {
  font-size: 18px;
  line-height: 23px;
  color: #19ce60;
}

.desc {
  margin-top: 140px;
}
.relatedWrap {
  position: relative;
  margin: 40px auto;

  text-align: center;
  box-sizing: border-box;
  width: 100%;
  max-width: 1200px;
}

.relatedTitle {
  font-size: 15px;
  width: 100%;
  margin: 40px auto 20px auto;
}

@media (max-width: 380px) {
  .product_info .product_name,
  .product_info .price,
  .sum .total_price {
    font-size: 20px;
  }

  .sum {
    display: block;
  }
}

@media (max-width: 720px) {
  .post_info {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    justify-content: center;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    padding-left: 45px;
    gap: 15px;
  }
  .post_info {
    gap: 15px;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    justify-content: center;
    overflow: hidden;
    padding-left: 0;
    text-overflow: ellipsis;
    width: calc(100% - 40px);
  }
  .TwoImg {
    gap: 10px;
  }
  .post_info .post_name {
    padding-left: 0px;

    grid-column: span 1;
  }
  .relatedWrap {
    position: relative;
    margin: 40px 0px;
    max-width: 1200px;
    left: 0;
    transform: translateX(0);
    width: calc(100vw);
    text-align: center;
  }
  .logo {
    top: 110px;
  }
}
