.filter {
  display: flex;
  width: 85%;
  margin: 0 auto;
  justify-content: flex-end;
}

@keyframes ani {
  0% {
    transform: rotate(-360deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.filter > p:not(:last-child)::after {
  content: "|";
  padding: 10px;
}

.filter p {
  cursor: pointer;
}

.grid_wrap {
  display: grid;
  box-sizing: border-box;
  width: 100%;
  /* max-width: 1600px; */
  grid-template-columns: repeat(3, 1fr);
  place-items: start;
  gap: 80px 40px;
  padding: 5px 50px 0 50px;
  margin: 0;
  margin-top: 0px;
}
.column1 {
  grid-template-columns: repeat(3, 1fr);
}
.column2 {
  grid-template-columns: repeat(4, 1fr);
}
.column3 {
  grid-template-columns: repeat(5, 1fr);
}
.column4 {
  grid-template-columns: repeat(6, 1fr);
}
.column5 {
  grid-template-columns: repeat(7, 1fr);
}

.screen {
  width: 100%;
  height: calc(100vh - 110px);
  overflow: scroll;
}
footer {
  padding: 2rem;
  text-align: center;
}
.Applogo {
  position: relative;
  width: 30px;
  left: 10px;
  top: 10px;
  /* filter: invert(1); */
  background-color: transparent;
  animation: ani 3s infinite;
}

.logo {
  position: fixed;
  top: 50px;
  width: 50px;
  height: 50px;
  z-index: 1;
  background-color: var(--backgroundColor);
}
.product {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-right: 50px;
}

.product_img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.product_img img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.product_info {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.product_info .seller_store {
  font-size: 18px;
  line-height: 23px;
  color: #767676;
  margin-bottom: 16px;
}

.product_info .product_name {
  font-size: 36px;
  line-height: 45px;
  color: #000000;
  margin-bottom: 20px;
}

.product_info .price {
  font-size: 36px;
  line-height: 45px;
  color: #000000;
  margin-bottom: 138px;
}

.product_info .unit {
  font-size: 18px;
  line-height: 23px;
  color: #000000;
}

.delivery {
  width: 150px;
  height: 20px;
  margin-bottom: 20px;
}

.delivery p {
  font-size: 16px;
  line-height: 20px;
  color: #767676;
}

.line {
  width: 600px;
  height: 2px;
  background: #c4c4c4;
  margin-bottom: 30px;
}

.amount {
  position: relative;
  width: 150px;
  height: 50px;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  margin-bottom: 30px;
}

.amount .minus {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 50%;
  left: 14px;
  transform: translateY(-50%);
  cursor: pointer;
}

.amount .count {
  position: absolute;
  width: 56px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #c4c4c4;
  border-top: none;
  border-bottom: none;
}

.amount .count span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.amount .plus {
  position: absolute;
  width: 20px;
  height: 40px;
  top: 50%;
  right: 14px;
  transform: translateY(-50%);
  cursor: pointer;
}

.sum {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.sum .sum_price {
  font-size: 18px;
  font-weight: 500;
  line-height: 23px;
  color: #000000;
}

.sum .total {
  font-size: 18px;
  line-height: 23px;
  color: #767676;
}

.sum .total_count {
  font-size: 18px;
  line-height: 23px;
  color: #19ce60;
}

.sum .total_count::after {
  content: "|";
  width: 5px;
  height: 23px;
  color: #c4c4c4;
  padding: 0px 11px;
}

.sum .total_price {
  font-size: 36px;
  line-height: 45px;
  color: #19ce60;
}

.sum .total_unit {
  font-size: 18px;
  line-height: 23px;
  color: #19ce60;
}

.btn .btn_buy {
  width: 416px;
  height: 60px;
  border-radius: 5px;
  background: #19ce60;
  border: none;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  line-height: 22px;
  color: #ffffff;
  margin-right: 44px;
}

.btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.btn .btn_cart {
  width: 200px;
  height: 60px;
  border-radius: 5px;
  background: #767676;
  border: none;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  line-height: 22px;
  color: #ffffff;
}

.desc {
  margin-top: 140px;
}

@media (max-width: 1440px) {
  .main {
    flex-direction: column;
  }

  .product {
    width: 100%;
    margin: 0;
    padding: 20px;
    padding-bottom: 0;
  }

  .sum .total_price {
    font-size: 24px;
  }

  .sum .sum_price {
    margin-right: 24px;
  }

  .btn {
    justify-content: center;
  }
  .btn .btn_buy {
    width: 70%;
  }

  .btn .btn_cart {
    width: 30%;
  }

  .line {
    width: 80%;
  }

  .product_info .price {
    margin-bottom: 50px;
  }
}

@media (max-width: 380px) {
  .product_info .seller_store,
  .btn .btn_cart,
  .btn .btn_buy {
    font-size: 14px;
  }

  .product_info .product_name,
  .product_info .price,
  .sum .total_price {
    font-size: 20px;
  }

  .sum {
    display: block;
  }
}

@media (max-width: 1440px) {
  .flex_wrap {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .flex_wrap {
    grid-template-columns: repeat(1, 1fr);
  }

  .grid_wrap {
    gap: 30px 10px;
    padding: 5px 10px 0 50px;
  }
  .column1 {
    display: flex;
    flex-direction: column;
  }
  .column2 {
    grid-template-columns: repeat(2, 1fr);
  }
  .column3 {
    grid-template-columns: repeat(3, 1fr);
  }
  .column4 {
    grid-template-columns: repeat(4, 1fr);
  }
  .column5 {
    grid-template-columns: repeat(5, 1fr);
  }
  .logo {
    position: fixed;
    top: 110px;

    width: 50px;
    height: 50px;
    z-index: 1;
  }
}

@media (max-width: 360px) {
  .filter p {
    font-size: 12px;
  }
}
