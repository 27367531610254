* {
  font-size: 15px;
  line-height: 24px;
}
.naviArrow {
  position: relative;
  margin-bottom: 30px;
  padding: 30px 0px;
}
.arrowPrev {
  left: 10px;
  font-size: 12px;
}
.arrowNext {
  right: 10px;
  font-size: 12px;
}
.hidden {
  display: none;
}
.detailNav {
  width: 100%;
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  height: fit-content;
  padding: 10px 10px;
}
