.fade-enter {
  display: absolute;
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: all 1s ease-in-out;
}

.fade-exit {
  display: absolute;
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: all 0.5s ease-in-out;
}
