.line {
  width: 600px;
  height: 2px;
  background: #c4c4c4;
  margin-bottom: 30px;
}
.amount {
  position: fixed;
  top: 100px;
  width: 50px;
  height: 100px;
  margin-bottom: 30px;
  z-index: 0;
  background-color: var(--backgroundColor);
  padding: 10px;
}
.amount .minus {
  position: relative;
  display: block;

  border-radius: 20px;
  height: 20px;
  width: 20px;
  left: 5px;

  font-size: 15px;
  cursor: pointer;
  line-height: 18px;
  text-align: center;
  border: solid 1px white;
}
.amount div {
  width: 100%;
  height: 1px;
  border-bottom: solid 1px white;
  margin: 15px 0px;
}
.amount .plus {
  position: relative;
  display: block;
  width: 20px;
  height: 20px;
  line-height: 18px;
  font-size: 19px;
  left: 5px;
  border: solid 1px white;
  border-radius: 20px;
  text-align: center;
  cursor: pointer;
}
.amount .count {
  position: absolute;
  width: 56px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #c4c4c4;
  border-top: none;
  border-bottom: none;
}
.amount .count span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.sum {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}
@media (max-width: 768px) {
  .amount {
    position: fixed;
    top: 160px;
    width: 50px;
    height: 100px;
    margin-bottom: 30px;
  }
}
