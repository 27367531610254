.header {
  width: 100%;
  height: 50px;
  display: grid;
  position: fixed;
  top: 0px;
  justify-content: space-between;
  grid-template-columns: 1fr 50px;
  align-items: start;
  z-index: 1;
}
.logo {
  z-index: 2;
  background-color: var(--backgroundColor);
  width: 50px;
  height: 50px;
}
.Applogo {
  position: relative;
  width: 30px;
  left: 10px;
  top: 10px;
  background-color: transparent;
  animation: ani 3s infinite;
  z-index: 2;
}
.inner {
  flex-shrink: 1;
  align-self: flex-start;
  align-items: center;
  gap: 10px;
  margin: 15px 0px 8px 15px;
}
.inner div {
  display: inline-block;
  margin-right: 10px;
}

.inner .logo {
  width: 124px;
  height: 38px;
  margin-right: 30px;
}
@keyframes ani {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
.inner .logo img {
  width: 124px;
  height: 38px;
}
.logoArrow {
  position: absolute;
  left: 20px;
  top: 16.5px;
  font-size: 12px;
  line-height: 18px;

  background-color: transparent;
}
.input_wrap {
  position: relative;
  width: 400px;
  height: 46px;
}

.input_wrap input {
  width: 400px;
  height: 46px;
  border: 2px solid #19ce60;
  border-radius: 50px;
  padding: 13px 22px;
  font-size: 16px;
  line-height: 20px;
  outline: none;
}

.input_wrap input::placeholder {
  font-size: 16px;
  line-height: 20px;
  color: #767676;
}

.input_wrap img {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  width: 19px;
  height: 19px;
}
.checkBox {
  display: none;
}
.checkBoxLable {
  display: flex;
  justify-items: center;
  align-items: center;
}
.checkBoxLable::before {
  display: block;
  content: " ";
  width: 16px;
  height: 16px;
  margin: 0px 5px;
  /* background-color: white; */
  border: solid 1px white;
  border-radius: 20px;
}

.menu {
  display: flex;
  width: 50px;
  height: 50px;
  /* align-items: center !important; */
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
}
.moreInfoBtn {
  cursor: pointer;
}
@media (max-width: 768px) {
  .input_wrap {
    display: none;
  }
  .header {
    height: auto;
  }
  .inner div {
    margin-right: 10px;
    margin-bottom: 5px;
  }
}
